.mbx-tab_navigation {
  &__list {
    @apply flex;
    position: relative;

    &--left {
      @apply justify-start;
    }

    &--right {
      @apply justify-end;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      @apply bg-black;
    }
  }

  &__item {
    position: relative;
    z-index: 1;

    .mbx-tab_navigation__list--fit & {
      flex: 1;
    }

    &.is-active {
      @apply text-red-700;

      &::after {
        @apply bg-red-700;
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        height: 4px;
      }
    }
  }

  &__link {
    @apply flex items-center justify-center font-bold px-20;
    height: 56px;
    font-size: 14px;
    line-height: 16px;

    &:not(.disabled) {
      &:hover {
        @apply text-red-700;
      }
    }

    &.disabled {
      opacity: 0.2;
    }
  }
}
