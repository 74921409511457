.mbx-direct-users-detail {
  table {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    tr {
      border-bottom: 1px solid #e0e0e0;
      td {
        @apply p-24;
        dl {
          @apply text-12_18;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          dt {
            width: 15%;
            min-height: 20px;
            margin: 4px 0;
          }
          dd {
            width: 85%;
            position: relative;
            min-height: 20px;
            margin: 4px 0;
            &::before {
              display: block;
              content: ':';
              position: absolute;
              top: -0.1em;
              left: -0.7em;
            }
          }
        }
      }
    }
  }
  &-user {
    @apply flex;
    @apply justify-between;
    @apply w-full;
    @apply mt-16;
    @apply mb-40;
    & > *:first-child {
      flex: 1;
    }
    & > *:last-child {
      width: 296px;
    }
    .mbx-basic_info {
      max-width: 640px;
    }
  }
  &-modal {
    border-radius: 4px;
  }

  &-notes {
    & > .outer {
      overflow: scroll;
      & > .inner {
      }
    }
  }
  &-offers-wrapper {
    border-top: 1px solid #e0e0e0;
  }
  &-offers {
    border-bottom: 1px solid #e0e0e0;
  }
}

.mbx-admin-form-title {
  @apply bg-gray-100;
  @apply p-24;
  @apply text-blueGray-700;
  @apply text-12_18;
  width: 144px;
  text-align: left;
  vertical-align: top;
}
