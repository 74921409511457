@import '../../mixins/mq';

.mbx-news {
  width: 100%;
  padding: 0 48px;
  overflow: hidden;
  background: url(/images/mypage-leaf-left.png) left top no-repeat;
  background-size: 448px auto;
  @apply bg-blue-50;
  @include mq('lg') {
    padding: 0;
  }
  @include mq('sm') {
    padding: 0;
    background-size: 107% auto;
  }
  &-inner {
    display: flex;
    position: relative;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    text-align: left;
    @include mq('sm') {
      display: block;
      padding: 32px 0 28px;
      text-align: center;
    }
  }
  &-heading {
    flex-basis: 250px;
    flex-shrink: 0;
    &-title {
      @apply text-heading_1 font-bold sm:text-heading_3;
      @include mq('sm') {
        br {
          display: none;
        }
      }
    }
    &-lead {
      @apply text-body_1 mt-16 sm:text-body_2 sm:mt-4;
    }
  }
  &-slider {
    width: 1107px;
    @include mq('sm') {
      width: 100%;
    }
    .slick-list {
      padding: 48px 20px;
      @include mq('sm') {
        padding-top: 24px !important;
        padding-bottom: 16px !important;
      }
      .slick-slide {
        @include mq('md') {
          &:not(.slick-active) {
            .mbx-news_module {
              a {
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }
    .slick-arrow {
      z-index: 10;
      width: 48px;
      height: 48px;
      transition: box-shadow 0.3s ease;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      font-size: 20px;
      &.slick-prev {
        left: 0px;
      }
      &.slick-next {
        left: calc(50vw - 48px - 16px + 230px);
      }
      .mbx-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @apply text-gray-400;
      }
      &:before {
        content: '';
      }
      &:hover {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
      }
    }
    .slick-dots {
      bottom: 10px;
      padding-left: 24px;
      text-align: left;
      @include mq('sm') {
        position: relative;
        bottom: 0;
        padding-left: 0;
        text-align: center;
      }
      li {
        margin: 0;
        vertical-align: top;
        button {
          &:before {
            content: '';
            top: 7px;
            left: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            opacity: 1;
            @apply bg-gray-400;
          }
        }
        &.slick-active {
          button {
            &:before {
              @apply bg-red-700;
            }
          }
        }
      }
    }
  }
}
