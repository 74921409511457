.mbx-dropdown {
  &-wrapper {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 300px;
    &:focus {
      outline: none;
    }
  }
  &-selections {
    @apply rounded-formContainer bg-white overflow-y-scroll;
    -ms-overflow-style: none; /* IE, Edge Scroll 表示 対応 */
    width: 100%;
    max-height: 300px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    &::-webkit-scrollbar {
      /* Chrome, Safari Scroll 表示 対応 */
      display: none;
    }
    &-item {
      position: relative;
      transition: background-color 0.3s ease, padding-left 0.3s ease;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      &:hover {
        @apply bg-blue-100;
      }
      &:last-child {
        border-bottom: 0;
      }
      &.is-select {
        padding-left: 22px;
        @apply bg-blue-100;
        .mbx-icon {
          opacity: 1;
        }
      }
      p {
        pointer-events: none;
      }
      .mbx-icon {
        position: absolute;
        top: 50%;
        transform: translate(-100%, -50%);
        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;
        @apply text-blue-700;
      }
    }
  }
  &-multiple {
    &-select {
      &-item {
        display: inline-block;
        &-icon {
          pointer-events: auto;
          .mbx-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            font-size: 18px;
            cursor: pointer;
            @apply text-blueGray-600 transition-colors;
            &:hover {
              @apply text-blueGray-400;
            }
          }
        }
      }
    }
  }
  &-scroll {
    position: relative;
    &-bar {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 4px;
      height: 0;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      z-index: 201;
      transition: ease;
    }
  }
}
