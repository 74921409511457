.event-contents {
    word-break: normal;

    ol {
        margin-left: 20px;
        list-style-type: decimal;
    }

    .event-list_mv {
        background: url(/images/matchbox-event-list_mv.jpg) no-repeat center center;
        background-size: cover;
    }

    >section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }

    .event-list {
        position: relative;

        &:hover {
            opacity: 0.6;
        }

        .data {

            >.status {
                padding: 2px 5px;
                color: #949494;
                border-width: 1px;
                border-style: solid;
                border-color: #949494;
            }

            >.status.open {
                color: #FF5A39;
                border-color: #FF5A39;
            }
        }

        .target {
            display: inline-flex;
            justify-content: flex-end;
            margin-top: auto;
        }
    }

    .event-list_pagination .pager>ul {
        justify-content: center;
    }

}

.event-detail {
    section {
        h2 {
            border-bottom: solid 2px #E2E2E2;
        }
    }

    .box-line {
        border: 1px solid #E2E2E2;
        box-sizing: border-box;
    }

    table.index {
        tr {

            td,
            th {
                border-color: rgb(226, 226, 226);
            }
        }
    }

    table.summary {
        tr {
            border-bottom: solid 1px rgb(226, 226, 226);

            th {
                vertical-align: top;
                white-space: nowrap;
            }
        }
    }

    .base-button {
        max-width: 490px;
        height: 56px;
        width: 100%;
    }

    .base-button .theme-link_secondary {
        pointer-events: none;
    }

    .textLink {
        color: #0A58A8;
        text-decoration: underline;

        &:hover {
            color: #3E9EFF;
            text-decoration: none;
        }
    }

    .mbx-naviInpage-item-icon {
        color: #9E9E9E;
        vertical-align: middle;
    }

    .mbx-icon-External_link {
        font-size: 125%;
        vertical-align: middle;
    }

    .hitokoto {
        display: inline-block;
        position: relative;
        box-sizing: border-box;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1.75rem;
            height: 1.75rem;
            border-top: 2px solid #E2E2E2;
            border-left: 2px solid #E2E2E2;
            margin: 0;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 1.75rem;
            height: 1.75rem;
            border-bottom: 2px solid #E2E2E2;
            border-right: 2px solid #E2E2E2;
            margin: 0;
        }

    }

}

.textlink_back {
    transition-duration: .15s;

    &:hover {
        opacity: 0.6;
    }

    i {
        display: inline-block;
        width: 22px;
        height: 22px;
        color: #FFF;
        line-height: 22px;
        border-radius: 50%;
        background: #9E9E9E;
    }
}

@media (min-width: 421px) {
    .event-detail {
        .base-button {
            height: 68px;
        }
    }
}

@media screen and (max-width: 767.99px) {
    html {
        scroll-padding-top: 70px;
    }

    .sp_none {
        display: none !important;
    }

    .event-contents {

        .event-list {

            .data {
                line-height: 1;

                >.event-data {
                    display: inline-block;
                    margin-right: 5px;
                    line-height: 1.4;
                    vertical-align: middle;
                }

                >.status {
                    display: inline-block;
                    padding: 3px 3px 2px;
                    font-size: 10px;
                    line-height: 1;
                }

            }

            h2 {
                line-height: 18px;
                font-weight: normal;

                br:not(:nth-child(1)) {
                    display: none;
                }
            }

            .target {
                font-size: 10px;
                line-height: 16px;
            }
        }

    }

    .event-list_pagination .pager>ul>li {
        margin-right: 24px;
    }

    .event-detail {
        /*        table.schedule {
            tr:first-child {
                th {
                    display: none;
                }
            }

            tr {
                display: inline-table;
                width: 100%;
            }

            tr:nth-child(n + 3) {
                th {
                    border-top: 0;
                }
            }

            td {
                display: block;
                width: 100%;
                padding: 6px 20px 0;
                border-top: 0;
                border-bottom: 0;
            }

            td:last-child {
                padding: 6px 20px;
                border-bottom: 1px solid #e2e2e2;
            }

        }
*/

        table.timetable {
            tr {
                &:nth-child(2) {
                    th {
                        padding: 10px 20px;
                    }
                }

                th {
                    padding: 20px;
                }

                td {
                    padding: 20px;
                }
            }
        }

        table.summary {
            th {
                padding: 20px 0;
            }

            td {
                padding: 20px 0 20px 30px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    html {
        scroll-padding-top: 120px;
    }

    .event-contents {
        >section {
            -webkit-column-gap: 40px;
            -moz-column-gap: 40px;
            column-gap: 40px;
        }

        .event-list_mv {
            height: 253px;

            .outset {
                width: 60%;
            }
        }

        .event-list {

            .event-thumb {
                width: 100%;
                display: inline-flex;
                align-items: stretch;

                img {
                    max-width: 374px;
                    width: inherit !important;
                    object-fit: cover;
                }
            }

            .data {

                >.event-data {
                    margin-right: 10px;
                }

            }

            >.event-overview {
                display: inline-flex;
                flex-direction: column;
                width: 100%;

                >.target {
                    margin-top: auto;
                }
            }

        }
    }

    .event-detail {
        section {
            h2 {
                padding-bottom: 24px;
            }
        }

        .event-instructor {
            padding: 40px;
            display: flex;
            /*justify-content: space-between;*/
        }

        .instructor-photo {
            margin-right: 40px;
            width: 320px;

            img {
                max-width: 320px;
                width: auto;
            }
        }

        .instructor-name {
            margin-bottom: 8px;
            font-size: 24px;
            line-height: 36px;
        }

        .instructor-prof {
            max-width: 540px;
        }

        /*        table.schedule {
            th {
                padding: 8px 40px;
            }

            td {
                padding: 8px 40px;
            }
        }
*/

        table.timetable {
            tr {
                &:nth-child(2) {
                    th {
                        padding: 20px 40px;
                    }
                }

                th {
                    padding: 40px;
                }

                td {
                    padding: 40px;
                }
            }
        }

        table.summary {
            th {
                padding: 40px;
            }

            td {
                padding: 40px 40px 40px 80px;
            }
        }

        .back_event-list .base-button .size-s .label {
            font-size: 14px;
            line-height: 16px;
        }

    }

}

@media screen and (min-width: 1024px) {
    .event-detail {
        .lg\:grid {
            .event-instructor {
                display: initial
            }

            .instructor-photo {
                margin-right: 0;
                width: auto;

                img {
                    max-width: 100%;
                }
            }

        }
    }
}