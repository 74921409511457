@import '../../mixins/mq';

// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=278%3A106
.mbx-notification {
  @apply absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 960px;
  &-pre-icon {
    @apply pl-16;
  }
  &-inner {
    @apply rounded-formContainer flex justify-center items-center;
  }
  &-message {
    @apply sm:text-left flex-grow font-bold;
  }
  &-icon {
    flex-basis: 24px;
    flex-shrink: 0;
    text-align: right;
    @include mq(sm) {
      flex-basis: 34px;
    }
    .mbx-button-iconButton {
      margin-left: auto;
    }
  }
}

// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=278%3A106
.mbx-notification--matchbou {
  @apply bg-red-100 px-10 md:px-16 lg:px-16 py-12 sm:py-4 sm:pr-12 sm:pl-6 text-center;
  .mbx-notification-message {
    @apply px-18 text-10_16 md:text-12_18 lg:text-12_18;
  }
  .mbx-notification-progress {
    flex-basis: 124px;
    flex-shrink: 0;
    text-align: left;
    @media (max-width: 765px) {
      flex-basis: 34px;
      .mbx-matchdegree--small {
        width: 34px;
      }
    }
  }
}

// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
.mbx-notification--error,
.mbx-notification--success {
  transform: translate(-50%, -100%);
  z-index: 100;
  .mbx-notification-inner {
    @apply bg-red-800 text-center text-white mb-32 sm:text-left sm:mx-16 sm:mb-16;
  }

  .mbx-notification-message {
    @apply py-19 sm:py-12 pr-14 pl-16 text-subhead_2 sm:text-caption_1;
  }
  .mbx-notification-icon {
    @apply my-14 mr-14;
    flex-basis: 28px;
  }
}

.mbx-notification--success {
  .mbx-notification-inner {
    @apply bg-gray-800;
  }
}

// 自動保存時のスタイル
// 上記スタイルと微妙に表示位置などが異なるため .mbx-notification のベースセレクタは利用せずにこのセレクタのみを指定する
// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=7864%3A145424
.mbx-notification--saved {
  @apply absolute;
  top: 0;
  left: 0;
  width: auto;
  max-width: 960px;
  z-index: 100;
  transform: translate(-50%, -100%);
  &-inner {
    @apply rounded-formContainer flex justify-center items-center bg-gray-800 mb-32;
  }
  &-message {
    @apply font-bold text-subhead_2 flex-grow whitespace-pre text-center text-white py-11 pr-16;
  }
  &-icon {
    flex-basis: 24px;
    flex-shrink: 0;
    text-align: right;
  }
  @include mq('sm') {
    left: 20px;
    transform: translate(0%, -100%);
  }
}

// PDFダウンロード
// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A173736&t=DYemNnu5kSHUaJmM-4
.mbx-notification--pdf-download {
  @apply w-auto sm:w-full;
  transform: translate(-50%, -100%);
  z-index: 100;
  .mbx-notification-inner {
    @apply bg-gray-800 text-center text-white mb-32 sm:text-left sm:mx-24 sm:mb-90;
  }

  .mbx-notification-message {
    @apply py-12 pr-22 pl-22 sm:px-18;
    h1 {
      @apply text-subhead_2 font-bold sm:text-center;
    }
    p {
      @apply text-caption_2 font-medium sm:text-center;
    }
  }
  .mbx-notification-icon {
    @apply my-14 mr-14;
    flex-basis: 28px;
  }
}

// SnackBarの縮小表示
// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A173736&t=DYemNnu5kSHUaJmM-4
.mbx-notification--mini {
  .mbx-notification-inner {
    @apply mb-0 rounded-t-4 rounded-b-none #{!important};
  }
  .mbx-notification-message {
    @apply py-7 px-11 #{!important};
  }
  .mbx-notification-icon {
    @apply my-5 mr-11 #{!important};
  }
  .mbx-notification-pre-icon {
    @apply pl-15 #{!important};
  }
  .mbx-notification-mini-hide {
    @apply hidden #{!important};
  }
}
