.portfolio-edit {
  .sp_only {
    display: none;
  }
  .sp_none{
    display: block !important;
  }
  .smooth-dnd-container {
    display: grid !important;
    grid-gap: 48px 48px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 48px;
    margin-bottom: 40px;
    @media screen and (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .add-portfolio {
    position: relative;
    border: 1px dashed;
    @apply border-blue-700;
    cursor: pointer;
    @apply text-blue-700;
    &:hover {
      border: 1px dashed #f4f9fe;
      background: #f4f9fe;
    }
    &:hover i::before {
      color: #a3bcd6;
    }
    &:hover p {
      color: #a3bcd6;
    }
  }
  &-preview-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.portfolio-detail{
  .sp_only {
    display: none;
  }
  .sp_none{
    display: block !important;
  }
  &-preview-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// モーダルスタイル
.modal-contents {
  //削除モーダル
  &_delete {
    border-radius: 4px;
    .btn-area {
      justify-content: center !important;
      .button {
        width: 146px;
        @media screen and (max-width: 767px) {
          width: 116px;
          height: 100%;
          margin: 0;
        }
      }
    }
  }
  //追加モーダル
  &_type,
  &_add {
    border-radius: 4px;
    @media screen and (max-width: 1123px) {
      width: 100vw;
    }
    @media screen and (max-width: 767px) {
      width: 100vw;
      height: auto;
    }
    .return-text {
      color: #0a58a8;
      cursor: pointer;
      &:hover {
        color: #3e9eff;
      }
      &:active {
        color: #003b78;
      }
    }
    .contents-area {
      @media screen and (max-width: 767px) {
        background-color: #ffffff;
      }
      .content {
        display: block;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        text-emphasis: center;
        cursor: pointer;
      }
    }
    .button-area{
      display: flex;
      justify-content: space-between;
      .base-button{
        width: 228px;
        margin: 0 auto;
      }
    }
  }
}

//SPのみの要素
@media screen and (max-width: 767px) {
  .portfolio-edit {
    &-preview-wrapper {
      justify-content: flex-end !important;
    }
    .sp_only {
      display: block;
    }
    .sp_none {
      display: none !important;
    }
    .header-image {
      display: none;
    }
    .portfolio-header_edit {
      display: none;
    }
    .portfolio-navigation {
      margin-top: 8px;
    }
    .sp-edit_btn {
      .base-button {
        width: 100%;
      }
    }

    .sp-modal {
      overflow: visible;
      .modal-overlay {
        z-index: 12;
      }
      .modal-inner {
        height: 100vh;
        margin-top: 0;
        overflow: scroll;
        text-align: left;
      }
      .btn-area {
        display: none;
      }
      .content {
        height: 100%;
      }
      .sp-edit {
        width: 100vw;
        height: 100%;
        background: #ffffff;
        &_inner {
          padding: 0 8px;
          .back {
            padding-top: 4px;
            padding-left: 16px;
            line-height: 48px;
          }
          .smooth-dnd-container {
            display: grid;
            grid-gap: 8px;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 16px;

            .wrapper {
              position: relative;
              .thumbnail {
                height: 26.4vw;
                background-size: cover;
                background-position: center;
                @apply bg-gray-50;
              }
              .delete {
                position: absolute;
                top: 0;
                right: 0;
                i {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  .portfolio-detail{
    &-preview-wrapper {
      justify-content: flex-end !important;
    }
    .sp_only {
      display: block;
    }
    .sp_none {
      display: none !important;
    }
    .sp-edit_btn {
      .base-button {
        width: 100%;
      }
    }
  }
  // モーダルスタイル
  .modal-contents {
    //追加モーダル
    &_add {
      .button-area{
        display: block;
        .base-button{
          display: block;
          width: 240px;
          margin: 4px auto;
        }
      }
    }
  }
}
