.base-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 48px;
  // cursor: pointer;
  &.w-full {
    width: 100%;
  }
  &.width-lg {
    @apply w-308;
  }
  &.isTextLink {
    height: auto !important;
    & > * {
      height: auto !important;
    }
  }
  .button {
    @apply transition-colors;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 14px;
    border-radius: 4px;
    outline: none;
    &.isWidthLarge {
      padding: 0 34px;
    }
    &:disabled {
      @apply text-button-disabled-text border-button-disabled-bg cursor-not-allowed #{!important};
      pointer-events: none;
    }
    & > img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  .size {
    &-m {
      height: 100%;
      .label {
        @apply text-button_medium;
      }
      .mbx-icon {
        @apply text-icon;
      }
    }
    &-s {
      height: 36px;
      border-width: 1px !important;
      .label {
        @apply text-button_small;
      }
      .mbx-icon {
        @apply text-icon_small;
      }
    }
    &-ss {
      height: 30px;
      border-width: 1px !important;
      .label {
        @apply text-button_ss;
      }
      .mbx-icon {
        @apply text-icon_ss;
      }
    }
    &-40 {
      height: 40px;
      .label {
        font-size: 14px;
      }
    }
  }
  .theme {
    &-primary {
      @apply text-white bg-button border-2 border-button;
      .mbx-state--hover &,
      &:hover {
        @apply bg-button-hover border-button-hover;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply bg-button-focus border-button-focus;
      }
    }
    &-secondary {
      @apply text-button bg-white border-2 border-button;
      .mbx-state--hover &,
      &:hover {
        @apply bg-blue-200;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply bg-button-focus border-button-focus text-white;
      }
    }
    &-tertiary {
      @apply text-white bg-button-secondary border-2 border-button-secondary;
      .mbx-state--hover &,
      &:hover {
        @apply bg-button-hover-secondary border-2 border-button-hover-secondary;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply bg-button-focus-secondary border-2 border-button-focus-secondary;
      }
    }
    &-quaternary {
      @apply text-button-secondary bg-white border-2 border-button-secondary;
      .mbx-state--hover &,
      &:hover {
        @apply bg-gray-200;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply bg-button-focus-secondary border-2 border-button-focus-secondary text-white;
      }
    }
    &-link {
      @apply text-button p-0;
      border-width: 0px !important;
      .mbx-state--hover &,
      &:hover {
        @apply text-button-hover;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply text-button-focus;
      }
    }
    &-link_secondary {
      @apply text-button-secondary p-0;
      border-width: 0px !important;
      .mbx-state--hover &,
      &:hover {
        @apply text-gray-500;
      }
      .mbx-state--focus &,
      &:focus,
      &:active {
        @apply text-button-focus-secondary;
      }
    }
  }
}
